import config from '../../../config.js'

function getDefaultState() {
    const locale = process.env.REACT_APP_I18N_LOCALE || 'en'
    // const free_of_charge = process.env.REACT_APP_FILTER_FREE_OF_CHARGE === 'true'
    return {
        max_power: [1, config.filterOptions.MAX_POWER],
        ccs_plug: true,
        chademo_plug: true,
        type2_plug: true,
        type2_socket: true,
        free_of_charge: false,
        partner: true,
        roaming: locale === 'hr',
        ehdv: false
    }
}

export {
    getDefaultState
}