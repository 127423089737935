import moment from "moment/moment"
import {IFilterOptions} from "../interface/filterOptions"
import Cookies from 'universal-cookie'
import config from "../config"

const cookies = new Cookies()

export const envs = {
    localhost: 'http://localhost:5173',
    dev: 'https://client.dev.gway.cloud',
    test: 'https://client.dev.gway.cloud',
    uat: 'https://client-uat.greenway.sk',
    uatPL: 'https://client-uat.greenwaypolska.pl',
    uatHR: 'https://client-uat.greenway.hr',
    production: 'https://client.greenway.sk',
    productionPL: 'https://client.greenwaypolska.pl',
    productionHR: 'https://client.greenway.hr'
}

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isMobilePhone() {
    return /(iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i.test(navigator.userAgent);
}

const buildQuery = (filterOptions: IFilterOptions) => {
    let query = ''
    Object.keys(filterOptions).forEach(key => {
        switch (key) {
            case 'max_power':
                if (typeof filterOptions[key][0] !== 'undefined') {
                    query = `${query}&max_power[from]=${filterOptions[key][0]}`
                }
                if (typeof filterOptions[key][1] !== 'undefined' && filterOptions[key][1] < config.filterOptions.MAX_POWER) {
                    query = `${query}&max_power[to]=${filterOptions[key][1]}`
                }
                break
            case 'ccs_plug':
                if (filterOptions[key]) {
                    query = `${query}&connector_type[ccs_plug]=1`
                }
                break
            case 'chademo_plug':
                if (filterOptions[key]) {
                    query = `${query}&connector_type[chademo_plug]=1`
                }
                break
            case 'type2_plug':
                if (filterOptions[key]) {
                    query = `${query}&connector_type[type2_plug]=1`
                }
                break
            case 'type2_socket':
                if (filterOptions[key]) {
                    query = `${query}&connector_type[type2_socket]=1`
                }
                break
            case 'free_of_charge':
                if (filterOptions[key]) {
                    query = `${query}&free_of_charge=1`
                }
                break
            case 'ehdv':
                if (filterOptions[key]) {
                    query = `${query}&ehdv=1`
                }
                break
            case 'roaming':
                if (filterOptions[key]) {
                    query = `${query}&roaming=1`
                }
                break
            case 'partner':
                filterOptions[key] ? query = `${query}&partner=1` : query = `${query}&partner=0`
                break
        }
    })
    return query.substring(1, query.length)
}

const checkLanguageInUrl = () => {
    const availableLanguages = ['en', 'pl', 'sk', 'hr']
    const url = new URL(window.location.href)
    const language = url.pathname.split('/').filter(el => el)
    if(availableLanguages.some( ai => language.includes(ai))) {
        return language[0]
    }
    return null
}

const getParamValue = (paramName) => {
    const url = window.location.search.substring(1) //get rid of "?" in querystring
    const qArray = url.split('&') //get key-value pairs
    for (let i = 0; i < qArray.length; i++)
    {
        const pArr = qArray[i].split('=') //split key and value
        if (pArr[0] === paramName)
            return pArr[1] //return value
    }
    return null
}

const getTimeIndication = (session) => {
    const now = moment().utc()
    const then = moment(session['server_time']).utc()
    const duration = now.diff(then)
    return `${moment.utc(duration).format("H:mm")}`
}

const getTimeIndicationPercentage = ({postponeMinutes, remainingMinutes}) => {
    // if postponeMinutes == 0 -> show empty bar - light blue circle
    return postponeMinutes ? (((postponeMinutes - remainingMinutes) / postponeMinutes) * 100) : 0
}

const getColor = (type: string, styles: object) => {
    switch (type?.toLowerCase()) {
        case 'available':
        case 'preparing':
        case 'finishing':
            return styles['greenColor']
        case 'charging':
            return styles['blueColor']
        case 'maintenance':
            return styles['redColor']
        case 'offline':
        case 'closed':
        case 'occupied':
        default:
            return styles['greyColor']
    }
}

const getCurrentStateText = (currentState, t) => {
    if (currentState['no_communication']) {
        return t('locationMap.offline')
    }

    if(typeof currentState?.measurements !== 'undefined') {
        if (currentState.measurements['state_of_charge']) {
            return `${Math.round(currentState.measurements['state_of_charge'])}%`
        }

        // if (currentState.session['server_time']) {
        //     const now = moment().local()
        //     const then = moment(currentState.session['server_time']).local()
        //     const duration = now.diff(then)
        //     return `${moment.utc(duration).format("H:mm")}`
        // }

        if (currentState.session['charging_from_utc']) {
            const now = moment().local()
            const then = moment(currentState.session['charging_from_utc']).local()
            const duration = now.diff(then)
            return `${moment.utc(duration).format("H:mm")}`
        }
    }

    // TODO Andrej - what if no info is provided?
    return t('locationMap.unknown')
}

const getClientZoneUrl = (origin) => {
    // From client zone
    // Localhost
    if(origin.indexOf('localhost') > -1){
        return envs.localhost
    }

    // gway cloud
    if(origin.indexOf('gway.cloud') > -1) {
        return envs.dev
    }

    // Test
    if(origin.indexOf('gwtest') > -1) {
        return envs.test
    }

    // UAT
    if(origin.indexOf('client-uat') > -1) {
        if(origin.indexOf('greenwaypolska') > -1) {
            return envs.uatPL
        }
        if(origin.indexOf('.hr') > -1) {
            return envs.uatHR
        }
        return envs.uat
    }

    // Production
    if(origin.indexOf('client.greenway') > -1) {
        if(origin.indexOf('greenwaypolska') > -1) {
            return envs.productionPL
        }
        if(origin.indexOf('.hr') > -1) {
            return envs.productionHR
        }
        return envs.production
    }

    // From map
    // Test
    if(origin.indexOf('map.gwtest.ninja') > -1) {
        return  envs.test
    }

    // UAT
    if(origin.indexOf('map-uat.greenway') > -1) {
        if(origin.indexOf('greenwaypolska') > -1) {
            return envs.uatPL
        }
        if(origin.indexOf('hr') > -1) {
            return envs.uatHR
        }
        return envs.uat
    }

    // Production
    if(origin.indexOf('map.greenway') > -1) {
        if(origin.indexOf('greenwaypolska') > -1) {
            return envs.productionPL
        }
        if(origin.indexOf('hr') > -1) {
            return envs.productionHR
        }
        return envs.production
    }

    return false
}

const getConnectorName = (type, t) => {
    switch (type) {
        case 'ConnectorType_CHAdeMO':
            return t('locationMap.chaDeMo')
        case 'ConnectorType_CCS':
            return t('locationMap.ccs')
        case 'ConnectorType_Type2Cable':
            return t('locationMap.type2c')
        case 'ConnectorType_Type2Socket':
            return t('locationMap.type2')
        case 'ConnectorType_HomeDe':
        default:
            return t('locationMap.unknown')
    }
}

const getBearerToken = () => {
    return cookies.get('gw_token') || getParamValue('bearerToken') || localStorage.getItem('bearerToken') || null
}

const stateCommander = (state) => {
    if(typeof state.command !== 'undefined' && state.command === 'StartCharging') {
        switch (state.status) {
            case 'Error':
                return 'error'
            case 'Finished':
                return 'getMeasurements'
            case 'Pending':
            case 'New':
            default:
                return 'initializing'

        }
    } else if (typeof state.command !== 'undefined' && state.command === 'StopCharging') {
        switch (state.status) {
            case 'Error':
                return 'error'
            case 'Finished':
                return 'getSummary'
            case 'Pending':
            case 'New':
            default:
                return 'initializing'

        }
    } else {
        // TODO - Andrej - what if nothing is fits?
        return null
    }
}

const getZoomLevel = (zoomLevel) => {
    return zoomLevel <= 15 ? 16 : 15
}

export {
    getTimeIndicationPercentage,
    getCurrentStateText,
    checkLanguageInUrl,
    getTimeIndication,
    getClientZoneUrl,
    getConnectorName,
    getBearerToken,
    stateCommander,
    getParamValue,
    isMobilePhone,
    getZoomLevel,
    buildQuery,
    getColor,
    iOS
}