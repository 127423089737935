import {makeStyles} from '@mui/styles'
import {Theme} from '@mui/material'

// @ts-ignore
export default makeStyles((theme: Theme) => ({
    filterWrapperDesktop: {
        position: 'absolute',
        bottom: '2.5vh',
        left: '2vw'
    },
    filterWrapper: {
        position: 'absolute',
        bottom: '115px',
        left: '2vw'
    },
    buttonWrapper: {
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        '&.MuiPaper-root': {
            borderRadius: '50%',
        }
    },
    button: {
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[700]
        },
        '&.MuiButtonBase-root': {
            margin: 0
        }
    },
    image: {
        width: 85,
        height: 85,

    },
    topSection: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px 16px 0 0',
        color: theme.palette.common.white,
        '& .MuiTypography-root': {
            color: theme.palette.common.white
        }
    },
    topLeftSection: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        paddingTop: '5px',
        textTransform: 'uppercase',
        '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '0.75rem',
            color: theme.palette.text.primary
        }
    },
    topRightSection: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingTop: '7px',
        paddingBottom: '10px',
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300]
        }
    },
    drawerContainer: {
        height: '100vh',
        overflow: 'auto',
        padding: 0,
        '& .MuiGrid-root': {
            alignItems: 'center'
        },
        '& .MuiTypography-root ': {
            paddingTop: '10px'
        }
    },
    titleWrapper: {
        textAlign: 'center'
    },
    section: {
        marginTop: '10px',
        textTransform: 'uppercase',
        padding: '0.5rem 0',
        paddingLeft: '10px',
        background: theme.palette.grey[200]
    },
    maxPowerSlider: {
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '20px'
    },
    maxPowerValue: {
        paddingRight: '15px',
        textAlign: 'right'
    },
    buttonsWrapperDesktop: {
        '& .MuiButton-root': {
            width: '50%',
            height: '40px',
            borderRadius: '25px'
        }
    },
    buttonsWrapper: {
        '& .MuiButton-root': {
            width: '90%',
            height: '40px',
            borderRadius: '25px'
        }
    },
    switchWrapper: {
        textAlign: 'right'
    },
    filterOptionWrapper: {
        '& .MuiGrid-root': {
            paddingLeft: '10px'
        }
    },
    dot: {
        position: 'absolute',
        top: '5px',
        right: 0,
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        backgroundColor: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.common.white}`
    },
    filterDrawerDesktop: {
        '& .MuiPaper-root': {
            width: '40%',
            paddingTop: '41px',
            '& .MuiBox-root': {
                top: '3px'
            }
        }
    },
    filterDrawerMobile: {
        '& .MuiPaper-root': {
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
        }
    },
    filterButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiTypography-subtitle2': {
            paddingTop: 0
        }
    },
    iconStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '4px !important',
        textAlign: 'center'
    },
    icon: {
        marginTop: '150px',
    }
}))