import React from 'react'
import {useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next'

// Config
import config from "../../../../config"

// Styles
import useStyles from './styles'

// MUI
import Box from "@mui/material/Box"
import {grey} from "@mui/material/colors"
import {styled} from "@mui/material/styles"
import {CloseOutlined} from "@mui/icons-material"
import CssBaseline from "@mui/material/CssBaseline"
import useMediaQuery from "@mui/material/useMediaQuery"
import FilterListIcon from '@mui/icons-material/FilterList'
import {
    Avatar, Button, CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Slider,
    SwipeableDrawer,
    Switch,
    Typography
} from "@mui/material"

// Hooks
import useFilter from "../../../../hooks/useFilter"
import useToggleFilter from "../../../../hooks/useToggleFilter"
import useFilterOptions from "../../../../hooks/useFilterOptions"
import useFilterChanged from "../../../../hooks/useFilterChanged"

// Store
import {RootState} from './../../../../store'

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
}

const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default
}))

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}))

const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}))

function valuetext(value: number) {
    return `${value}°C`
}

const FilterLocations = (props: Props) => {
    const {window} = props
    const { t } = useTranslation()
    const classes = useStyles()

    // Media query
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const drawerBleeding = isDesktop ? 0 : 40

    // Filter from store
    const {filter} = useFilter()

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined
    const {filterOptions, setFilterOptions, resetToDefault, filterHasChanges} = useFilterOptions()
    const {filterChanged, setFilterChanged} = useFilterChanged()
    const {toggleFilter, setToggleFilter} = useToggleFilter()

    const getFilterName = (filterName: string) => {
        if(typeof filterName === 'undefined') {
            return
        }

        if(filterName?.indexOf('.') > -1) {
            return filterName.split('.')[1]
        }
        return filterName
    }

    const changeFilterOptions = (filterName: string, checked: boolean) => {
        setFilterOptions(filterName, checked)
    }

    return (
        toggleFilter && (
            <div className={isDesktop ? classes.filterWrapperDesktop : classes.filterWrapper}>
                <Paper elevation={3} square={false} className={classes.buttonWrapper}>
                    <IconButton edge="start"
                                color="inherit"
                                onClick={() => {
                                    setToggleFilter(true)
                                    setFilterChanged(filterChanged+1)
                                }}
                                aria-label="close"
                                className={classes.button}
                    >
                        {
                            filterHasChanges() && (
                                <div className={classes.dot}/>
                            )
                        }
                        <Avatar sx={{bgcolor: 'white'}}>
                            <FilterListIcon className={classes.image}/>
                        </Avatar>
                    </IconButton>
                </Paper>
                <Root>
                    <CssBaseline/>
                    <SwipeableDrawer
                        className={isDesktop ? classes.filterDrawerDesktop : classes.filterDrawerMobile}
                        container={container}
                        anchor={isDesktop ? 'left': 'bottom'}
                        open={toggleFilter}
                        onOpen={() => {}}
                        onClose={() => {
                            setToggleFilter(false)
                            setFilterChanged(filterChanged+1)
                        }}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                            keepMounted: false,
                        }}
                    >
                        <StyledBox
                            sx={{
                                position: 'absolute',
                                top: -drawerBleeding,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                visibility: 'visible',
                                right: 0,
                                left: 0
                            }}
                        >
                            {
                                !isDesktop && (
                                    <Puller/>
                                )
                            }
                            <div className={classes.topSection}>
                                <div className={classes.topLeftSection}>
                                    {
                                        isDesktop && (
                                            <Typography variant={'h6'}>
                                                {t('locationMap.filterOptions')}
                                            </Typography>
                                        )
                                    }
                                </div>
                                <div className={classes.topRightSection}>
                                    <CloseOutlined onClick={() => {
                                        setToggleFilter(false)
                                        setFilterChanged(filterChanged+1)
                                    }}/>
                                </div>
                            </div>
                        </StyledBox>
                        {
                            filterOptions && filter ? (
                                <Grid container className={classes.drawerContainer}>
                                    {!isDesktop && (
                                        <Grid item xs={12} className={classes.titleWrapper}>
                                            <Typography variant={'h6'}>{t('locationMap.filterOptions')}</Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <section className={classes.section}>
                                            <Typography variant={'caption'}>
                                                {t('locationMap.maxPower')}
                                            </Typography>
                                        </section>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.maxPower')}
                                        </Grid>
                                        <Grid item xs={6} className={classes.maxPowerValue}>
                                            <Typography variant={'subtitle2'}>
                                                {filterOptions['max_power'][0]} - {filterOptions['max_power'][1]}{filterOptions['max_power'][1] === config.filterOptions.MAX_POWER ? '+' : ''} kW
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={1}/>
                                        <Grid item xs={10}>
                                            <Box className={classes.maxPowerSlider}>
                                                <Slider value={filterOptions['max_power']}
                                                        onChange={(event, newValue: number[]) => {
                                                            setFilterOptions('max_power', newValue)
                                                        }}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={valuetext}
                                                        color="secondary"
                                                        min={1}
                                                        max={config.filterOptions.MAX_POWER}
                                                        disableSwap={true}
                                                        data-testid="maxPowerFilter"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {filter.map((group, index) => (
                                        <Grid item xs={12} key={index}>
                                            <section className={classes.section}>
                                                <Typography variant={'caption'}>{t(`${group.group_translation_group || 'locationMap'}.${group.group_translation_key}`)}</Typography>
                                            </section>

                                            {group.filters.map((filter, filterIndex) => (
                                                <Grid container className={classes.filterOptionWrapper} key={filterIndex}>
                                                    <Grid item xs={6}>
                                                        {t(`${filter.translation_group || 'locationMap'}.${filter.translation_key || filter.label}`)}
                                                    </Grid>

                                                    <Grid item xs={6} className={classes.switchWrapper}>
                                                        {filter.type === 'boolean' ? (
                                                            <Switch
                                                                color="secondary"
                                                                checked={filterOptions[getFilterName(filter.name)]}
                                                                onChange={(event, checked) => changeFilterOptions(getFilterName(filter.name), checked)}
                                                                data-testid={filter.name}
                                                            />
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} className={isDesktop ? classes.buttonsWrapperDesktop : classes.buttonsWrapper}>
                                        <Box sx={{ '& button': { m: 1 } }}>
                                            <div className={classes.filterButtonWrapper}>
                                                <Button fullWidth variant="outlined" color="secondary" onClick={resetToDefault} data-testid="resetToDefaults">
                                                    <Typography variant={'subtitle2'}>{t('locationMap.resetToDefaults')}</Typography>
                                                </Button>
                                            </div>
                                            <div className={classes.filterButtonWrapper}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        setToggleFilter(false)
                                                        setFilterChanged(filterChanged + 1)
                                                    }}
                                                    data-testid="applyAndClose"
                                                >
                                                    <Typography variant={'subtitle2'}>{t('locationMap.applyAndClose')}</Typography>
                                                </Button>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container className={classes.drawerContainer}>
                                    <Grid item xs={12}>
                                        <InputAdornment position="end" className={classes.iconStyle}>
                                            <CircularProgress color="primary"
                                                              className={classes.icon}
                                                              size={50}
                                            />
                                        </InputAdornment>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </SwipeableDrawer>
                </Root>
            </div>
        )
    )
}

export default FilterLocations