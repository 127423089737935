import {createSlice} from '@reduxjs/toolkit'
import {getDefaultState} from './helper'

const filterSlice = createSlice({
    name: 'filter',
    filter: null,
    initialState: {
        toggleFilter: false,
        hasChanges: false,
        filterOptions: getDefaultState()
    },
    reducers: {
        resetFilterOptions: (state) => {
            state.filterOptions = getDefaultState()
        },
        setFilterDefinition: (state, action) => {
          state.filter = action.payload.value
        },
        setFieldValueOfFilter: (state, action) => {
            state[action.payload.field] = action.payload.value
        },
        setFilterValue: (state, action) => {
            state.filterOptions[action.payload.field] = action.payload.value
        }
    }
})

export const {setFilterDefinition, setFieldValueOfFilter, resetFilterOptions, setFilterValue} = filterSlice.actions
export default filterSlice.reducer