import {RootState} from './../store'
import {useDispatch, useSelector} from 'react-redux'
import {setFilterDefinition} from '../store/features/Filter'

const useFilter = () => {
    const dispatch = useDispatch()
    const filter = useSelector((state: RootState) => state.filter.filter)
    const setFilter = (value) => {
        dispatch(setFilterDefinition({
            field: 'filter',
            value
        }))
    }
    return {
        filter,
        setFilter
    }
}

export default useFilter